import HeaderView from "../HeaderView/HeaderView";
import QRCode from "react-qr-code"
import './QrCodeView.css'
import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import {Api, ApiAuthentication} from "../../RequestsProvider/RequestProvider";
import HeadNavigation from "../../Components/HeadNavigation/HeadNavigation";
interface Props {
    apiCredentials: ApiAuthentication | null
    setApiCredentials: Dispatch<SetStateAction<ApiAuthentication | null>>
}

export const QrCodeView = ({apiCredentials, setApiCredentials}: Props) => {


    const request = useContext(Api)


    useEffect(() => {
        const getApiKeyInterval = setInterval(() => getApiKeyInformation().then(apiCredentials => {
            setApiCredentials(apiCredentials.message)
        }), 2000)

        return () => clearInterval(getApiKeyInterval)
    }, [])

    if (!request) {
        return (<></>)
    }

    const {getApiKeyInformation} = request


    return (
        apiCredentials ? (<>
            <HeaderView/>
            <HeadNavigation path={[{path: "/", pathName: "Startseite"}, {path: null, pathName: "WhatsApp verbinden"}]}/>
            {apiCredentials.qrCode ? <div className="grid-padding">
                <div className="site-header d-flex justify-content-between align-items-center">
                    <h1>WhatsAppBot <span>[verbinden]</span></h1>
                </div>
                <div className="site-header d-flex justify-content-between align-items-center">
                    <p>Scanne den QR-Code, um den Bot mit deinem WhatsApp zu verbinden.</p>
                </div>

            </div>: null}
            <div className="container">
                <div className="row">
                    {apiCredentials.qrCode ? <QRCode
                            size={512}
                            fgColor={'#ffffff'}
                            bgColor={'#000000'}
                            value={apiCredentials.qrCode}
                        /> :
                        <div>
                            <div className="col-12 d-flex justify-content-center mt-5"><h4>Dein Bot wird für dich
                                vorbereitet...</h4></div>
                            <div className="col-12 d-flex justify-content-center">dies kann noch ein paar Sekunden dauern</div>
                            <div className="col-12 d-flex justify-content-center">
                                <div className="loader_wrapper">
                                    <svg className="loader" width="65" height="65" viewBox="0 0 44 44">
                                        <circle cx="22" cy="22" r="20" fill="none" strokeWidth="4"></circle>
                                    </svg>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </>) : null
    )
}