import React, {useContext, useEffect, useState} from "react";
import './LandingPage.css'
import {PlusLg, Dot} from "react-bootstrap-icons";
import CButtonGreenFilled from "../../Components/ButtonFilled/CButtonGreenFilled";
import mockup1 from "../../assets/images/mockup/iMockup - iPhone 14.png";
import mockup2 from "../../assets/images/mockup/iMockup - iPhone 14-1.png";
import mockup3 from "../../assets/images/mockup/iMockup - Google Pixel 8 Pro.png";
import mockup4 from "../../assets/images/mockup/iMockup - birthday.png";
import mockup5 from "../../assets/images/mockup/iMockup - deleted.png";
import CButtonFilled from "../../Components/ButtonGreenFilled/CButtonFilled";
import moment from "moment/moment";
import {TierCard} from "../../Components/TierCard/TierCard";
import {AccountTier, Api} from "../../RequestsProvider/RequestProvider";
import {BarLoader} from "react-spinners";
import {Context} from "../../ContextProvider/Context";
import {useNavigate} from "react-router-dom";


export const LandingPage = () => {


    const [tierList, setTierList] = useState<AccountTier[]>()

    const context = useContext(Context)
    const request = useContext(Api)
    const navigate = useNavigate()
    useEffect(() => {
        getTierList().then(tl => {
            if(tl){
                setTierList(tl.message)
                setTimeout(() => setContentLoading(false), 1350)
            }
        })
    }, [])

    if (!context || !request) {
        return (<></>)
    }

    const {contentLoading, setContentLoading} = context
    const {getTierList, getApiKeyInformation} = request
    const renderLoading = () => {
        return contentLoading ? <BarLoader
            color={'#25D366'}
            height={2}
            width={"100%"}
            cssOverride={{backgroundColor: "transparent", position: 'absolute'}}
            speedMultiplier={.8}
        /> : null
    }
    return (
        <>
            {renderLoading()}
            <section className="section section-1 container-fluid">
                <div className="row bg-black px-2 py-1">
                    <div className="row m-1">
                        <div className="col-8 col-lg-6 text-wa_green">
                            <div className="row fs-22">WhatsAppBot</div>
                            <div className="row fs-12 font-regular">mache deine Chats einzigartig!</div>
                        </div>
                        <div className="col-4 col-lg-6 justify-content-end d-flex">
                            <button className={"btn fw-bold my-1"}
                                    onClick={() => navigate("/login", {replace: true})}>Login
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row px-1">
                    <div className="head-navigation">
                        <div className="col-6 text-truncate">
                            <span className={"text-muted font-regular"}>Der erste amerikanische Astronaut im Weltraum war Alan B. Shepard Jr</span>
                        </div>
                        <div className="col-6 justify-content-end d-flex px-2">
                            <CButtonFilled content={"Registrieren"} onClick={() => {
                                navigate("/register", {replace: true})
                            }} icon={<PlusLg/>}/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}